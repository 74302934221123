import React, { useState } from "react";
import BookImage from '../assets/images/book_image.png'
import '../assets/styles/Book.sass'

export default function Book(){
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const openModal = () => {
    document.querySelector('.modal').style.display = 'flex';
  }

  function checkData(e) {

    if (checkbox) {

      if (name != "" && email != "" && phone != "") {
        sendData(name, email, phone);
      } else {
        alert('Preencha todos os campos antes de enviar');
      }

    } else {
      alert('Preencha o checkbox antes de enviar');
    }

  }

  function getMediaFromURL() {
    var url = window.location.href;
    var allParams = url.split('?');

    if (allParams.length < 2) {
      return 'Direct'
    }

    var params = allParams[1].split('&');

    var source, utm_source, utm_medium, utm_campaign;

    if (params.find(element => element.includes('gclid')) != undefined) {
      // gclid = params.find(element => element.includes('utm_campaign')).split('=')[1];
      return 'Google Ads';
    }


    if (params.find(element => element.includes('utm_source')) != undefined) {
      source = params.find(element => element.includes('utm_source')).split('=')[1];
      return source;
    }


    return 'Landing page';
  }

  function sendData(name, email, phone) {
    var midia = getMediaFromURL();
    fetch('https://mozak.hypnobox.com.br/email.receber.php', {
      method: 'POST',
      body: JSON.stringify({
        nome: name,
        email: email,
        tel_celular: phone,
        id_produto: 35,
        midia: midia
      })
    })
      .then(response => response.json())
      .then(data => {
        alert('Dados enviados com sucesso!');
        setName("");
        setEmail("");
        setPhone("");
        setCheckbox(false);
        window.location.reload();
      })
  }

  const activeLabel = (e) => {
    const label = e.target.parentNode.querySelector('label')
    label.classList.add('active')
  }

  const toggleLabel = (e) => {
    const input = e.target
    const label = e.target.parentNode.querySelector('label')

    if (input.value == "") {
      label.classList.remove('active')
      return
    }

  }

  return (
    <section className="book">
      <p className="title">BOOK</p>

      <div className="image">
        <img src={BookImage} alt="book_image" />
      </div>
      
      <div className="form">
        <h2>Cadastre-se para acessar o book e receber informações exclusivas dos nossos consultores</h2>

        <div className="input_wrapper">
          <input type="text" id="book_name" required onFocus={activeLabel} onBlur={toggleLabel} onChange={(e) => setName(e.target.value)} />
          <label htmlFor="book_name">Nome</label>
        </div>
        
        <div className="input_wrapper">
          <input type="text" id="book_email" required onFocus={activeLabel} onBlur={toggleLabel} onChange={(e) => setEmail(e.target.value)} />
          <label htmlFor="book_email">Email</label>
        </div>
        
        <div className="input_wrapper">
          <input type="text" id="book_phone" required onFocus={activeLabel} onBlur={toggleLabel} onChange={(e) => setPhone(e.target.value)} />
          <label htmlFor="book_phone">Telefone</label>
        </div>

        <div className="checkbox_wrapper">
          <input type="checkbox" id="book_checkbox" required onChange={(e) => setCheckbox(e.target.checked)} />
          <label htmlFor="book_checkbox">Li e aceito os termos e <span onClick={openModal}>políticas de privacidade</span></label>
        </div>

        <button onClick={checkData}>ENVIAR</button>



      </div>

    </section>
  );
}