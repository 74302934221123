import logo from './logo.svg';
import './assets/styles/App.sass';
import './assets/styles/Button.sass';
import React, { useState } from "react";
import Modal from './components/Modal';
import DocumentMeta from "react-document-meta";
import Cta from './components/Cta';
import Navbar from './views/Navbar'
import Hero from './views/Hero'
import Video from './views/Video'
import Datasheet from './views/Datasheet'
import DatasheetText from './views/DatasheetText'
import Project from './views/Project'
import ProjectText from './views/ProjectText'
import Location from './views/Location'
import Book from './views/Book'
import Footer from './views/Footer'
import Menu from './components/Menu'
import AMozak from './views/AMozak';

function App() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const meta = {
    title: 'Villa - Mozak',
    description: 'Lançamento Leblon - Mozak',
    // canonical: 'http://example.com/path/to/page',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'mozak,leblon,empreendimento,lançamento,comercial,luxo,imobiliária,imóveis,imobiliárias,imobiliárias no rio de janeiro,imóveis no rio de janeiro,imóveis no rio,imóvel'
      }
    }
  };

  function checkData(e) {

    if (checkbox) {


      if (name != "" && email != "" && phone != "") {
        sendData(name, email, phone);
      } else {
        alert('Preencha todos os campos antes de enviar');
      }

    } else {
      alert('Preencha o checkbox antes de enviar');
    }

  }

  function getMediaFromURL() {
    var url = window.location.href;
    var allParams = url.split('?');

    if (allParams.length < 2) {
      return 'Direct'
    }

    var params = allParams[1].split('&');

    var source, utm_source, utm_medium, utm_campaign;

    if (params.find(element => element.includes('gclid')) != undefined) {
      // gclid = params.find(element => element.includes('utm_campaign')).split('=')[1];
      return 'Google Ads';
    }


    if (params.find(element => element.includes('utm_source')) != undefined) {
      source = params.find(element => element.includes('utm_source')).split('=')[1];
      return source;
    }


    return 'Landing page';
  }

  function sendData(name, email, phone) {
    var midia = getMediaFromURL();
    fetch('https://mozak.hypnobox.com.br/email.receber.php', {
      method: 'POST',
      body: JSON.stringify({
        nome: name,
        email: email,
        tel_celular: phone,
        id_produto: 35,
        midia: midia
      })
    })
      .then(response => response.json())
      .then(data => {
        alert('Dados enviados com sucesso!');
        setName("");
        setEmail("");
        setPhone("");
        setCheckbox(false);
        window.location.reload();
      })
  }

  function openModal() {
    document.querySelector('.modal').style.display = 'flex';
  }

  function activeLabel(e) {
    const input = e.target
    const label = input.previousSibling

    label.classList.add('active')
  }

  function blurLabel(e) {
    const input = e.target
    const label = input.previousSibling
    if (input.value == "") {
      label.classList.remove('active')
    }
  }

  function scrollToForm() {
    window.scrollTo({
      top: document.querySelector('.body').offsetTop,
      behavior: 'smooth'
    });
  }

  return (
    <div className="App">
      <DocumentMeta {...meta} />

      <Navbar />

      <Hero />

      <Video />

      <Datasheet />

      <DatasheetText />

      <Project />

      <ProjectText />

      <Location />

      <Book />
      <AMozak />

      <Footer />

      <Modal />
      <Cta />
      <Menu />
    </div>
  );
}

export default App;
