import '../assets/styles/Menu.sass';
import MenuIcon from '../assets/images/menu.png'

export default function Menu(){

  const closeMenu = () => {
    document.querySelector('.menu_nav').classList.remove('active')
  }

  const scrollToSection = (section) => {
    window.scrollTo({
      top: document.querySelector(section).offsetTop,
      behavior: 'smooth'
    });

    closeMenu()
  }

  return (
    <div className="menu_nav">
      <ul>
        <li onClick={() => scrollToSection('.hero')}>INÍCIO</li>
        
        <li onClick={() => scrollToSection('.video')}>VÍDEO</li>
        
        <li onClick={() => scrollToSection('.datasheet')}>FICHA TÉCNICA</li>
        
        <li onClick={() => scrollToSection('.project')}>O PROJETO</li>
        
        <li onClick={() => scrollToSection('.location')}>LOCALIZAÇÃO</li>

        <li onClick={() => scrollToSection('.book')}>BOOK</li>
      </ul>
    </div>
  );
}