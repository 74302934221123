import '../assets/styles/Hero.sass';
import HeroImage from '../assets/images/hero_image.png'
import HeroImageMobile from '../assets/images/hero_image_mobile.png'
import HeroLogo from '../assets/images/hero_logo.png'
import HeroLogoMobile from '../assets/images/hero_logo_mobile.png'

export default function Hero() {

  const scrollToSection = (section) => {
    window.scrollTo({
      top: document.querySelector(section).offsetTop,
      behavior: 'smooth'
    });

  }

  return (
    <div className="hero">
      
      <div className="text">
        <img className="logo_desktop" src={HeroLogo} alt="Hero" />
        <img className="logo_mobile" src={HeroLogoMobile} alt="Hero" />

        <h1>SENTIMENTO AUTÊNTICO DE PERTENCER</h1>
        
        <p>
          A arquitetura dança <br/> 
          E a beleza se funde em cada traço <br/> 
          Entre paredes, histórias são contadas <br />
          Preservadas <br />
          Vividas <br />
          Como é bom estar em casa <br />
        </p>

        <p>Viva o verão o ano inteiro no residencial que une a identidade pulsante do Rio ao aconchego de um lar</p>

        <button onClick={() => scrollToSection('.video')}>SAIBA MAIS</button>
      </div>
      
      <div className="image">
        <img className="image_desktop" src={HeroImage} alt="Hero" />
        <img className="image_mobile" src={HeroImageMobile} alt="Hero" />
      </div>

    </div>
  );
}