import Menu from '../assets/images/menu.png';
import Mozak from '../assets/images/mozak.png';
import Bsd from '../assets/images/bsd.png';
import '../assets/styles/Navbar.sass';

export default function Navbar(){

  const openMenu = () => {
    document.querySelector('.menu_nav').classList.toggle('active');
  }

  return (
    <div className="navbar">
      <img src={Menu} alt="Menu" className="menu_icon_navbar" onClick={openMenu}/>
      <img src={Mozak} alt="Mozak" className="mozak" />
      <img src={Bsd} alt="BSD" className="bsd" />
    </div>
  );
}