import FichaTecnica from '../assets/images/ficha_tecnica.png';
import FichaTecnicaMobile from '../assets/images/ficha_tecnica_mobile.png';
import Ficha1 from '../assets/images/ficha1.png';
import Ficha2 from '../assets/images/ficha2.png';
import Ficha3 from '../assets/images/ficha3.png';
import Ficha4 from '../assets/images/ficha4.png';
import Ficha5 from '../assets/images/ficha5.png';
import Ficha6 from '../assets/images/ficha6.png';
import Ficha7 from '../assets/images/ficha7.png';
import ArrowLeft from '../assets/images/arrow_left.png';
import ArrowRight from '../assets/images/arrow_right.png';

import '../assets/styles/Datasheet.sass';

import { useState } from 'react';

export default function Datasheet() {
  const [index, setIndex] = useState(0);

  const images = [
    Ficha1,
    Ficha2,
    Ficha3,
    Ficha4,
    Ficha5,
    Ficha6,
    Ficha7
  ]

  const clickLeft = () => {
    if (index > 0) {
      setIndex(index - 1)
    } else {
      setIndex(images.length - 1)
    }
  }

  const clickRight = () => {
    if (index < images.length - 1) {
      setIndex(index + 1)
    } else {
      setIndex(0)
    }
  }


  return (
    <section className="datasheet">
      <p className="title">FICHA TÉCNICA</p>

      <div className="ficha">
        <div className='content'>
          <div className='section'>
            <h1>Rua General Venâncio Flores, 555 - Leblon</h1>
          </div>
          <div className='section two'>
            <div>
              <h2><strong>24</strong> UNIDADES</h2>
              <h2>entre 34 m² e 75 m²</h2>
            </div>
            <div>
              <div className='detail'></div>
              <div>
                <h2>7 Gardens</h2>
                <h2>14 Tipos</h2>
                <h2>3 Coberturas</h2>
              </div>
            </div>
          </div>
          <div className='section'>
            <h2>Estúdio, sala/quarto, sala/2 quartos</h2>
          </div>
          <div className='section'>
            <h2>Único projeto de double suites do Leblon</h2>
          </div>
        </div>
      </div>

      <div className="carrousel">
        <img className="arrow_left" src={ArrowLeft} alt="" onClick={clickLeft} />
        <img src={images[index]} alt="" />
        <img className="arrow_right" src={ArrowRight} alt="" onClick={clickRight} />
      </div>

    </section >
  );
}