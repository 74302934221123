import React from "react";
import Whatsapp from '../assets/images/whatsapp_button.png';
import Phone from '../assets/images/phone_button.png';
import '../assets/styles/Cta.sass'

export default function Cta(){
  return (
    <div className="cta">
      <a href="https://mozak.hypnobox.com.br/?controle=Atendimento&acao=index&produto=35&" target="_blank">
        <img src={Phone} alt="Mensagem" />
      </a>
      <a href="https://wa.me/5521997442478" target="_blank">
        <img src={Whatsapp} alt="Whatsapp" />
      </a>
    </div>
  );
}